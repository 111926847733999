
import { Component, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";
import Preloader from "@/components/Preloader.vue";
import settingsRoutes from "@/api/routes/settings";
import SettingsBanner, { bannerTypes } from "@/models/settings/banner";
import { plainToClass } from "class-transformer";

@Component({
  name: "VBanners",
  components: {
    Preloader
  }
})
export default class VBanners extends Vue {
  public preload: boolean = false;

  public banner_types = bannerTypes;
  public ru = ru;

  public banners: SettingsBanner[] = [];

  public current_banner: SettingsBanner = new SettingsBanner();
  public current_banner_index!: number;

  protected async created() {
    this.preload = true;

    await this.loadBanners();

    this.preload = false;
  }

  protected async loadBanners() {
    await this.$api
      .get(settingsRoutes.banners)
      .then(({ data: res }: { data: SettingsBanner[] }) => {
        this.banners = plainToClass(SettingsBanner, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public clearExpiredAt() {
    this.current_banner.expired_at = null;
  }

  public beforeDestroyBanner(index: number) {
    this.current_banner_index = index;

    this.$modal.show("destroyBannerModal");
  }

  public async destroyBanner() {
    const banner = this.banners[this.current_banner_index];

    await this.$api
      .destroy(settingsRoutes.banner(banner.id))
      .then(() => {
        this.$delete(this.banners, this.current_banner_index);

        this.$modal.hide("destroyBannerModal");
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async switchActiveBanner(index: number) {
    this.current_banner_index = index;
    this.current_banner = this.banners[index];

    await this.updateBanner();
  }

  public async updateBanner() {
    await this.$api
      .put(settingsRoutes.banner(this.current_banner.id), {
        banner: this.current_banner
      })
      .then(({ data: res }: { data: SettingsBanner }) => {
        this.$set(
          plainToClass(SettingsBanner, res),
          this.current_banner_index,
          this.banners
        );

        this.$modal.hide("editBannerModal");
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  public async beforeCreateBanner(index?: number) {
    if (index != -1) {
      this.current_banner_index = index!;
      this.current_banner = this.banners[index!];
    } else {
      this.current_banner = new SettingsBanner();
    }

    this.$modal.show("editBannerModal");
  }

  public async createBanner() {
    await this.$api
      .post(settingsRoutes.banners, {
        banner: this.current_banner
      })
      .then(({ data: res }: { data: SettingsBanner }) => {
        this.banners.push(plainToClass(SettingsBanner, res));

        this.$modal.hide("editBannerModal");
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
